<template>
  <div class="app-mall-order container">
    <div class="content ex padding-20-0">
      <!-- <el-tabs v-model="orderType" @tab-click="toQuery">
      <el-tab-pane name="purchase" label="采购订单">-->
      <div>
        <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="采购开始日期" end-placeholder="采购结束日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="toQuery" />
        <el-input v-model="query.formCode" :maxlength="20" placeholder="输入订单编码进行搜索" style="width: 180px;" />
        <el-button class="filter-item" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      </div>

      <el-table v-loading="loading" row-key="id" :data="data" border style="margin-top: 10px;">
        <el-table-column label="订单编号" width="150">
          <template slot-scope="scope">
            <div>{{scope.row.formCode}}</div>
            <div class="fc-g fs-small">{{new Date(scope.row.purchaseTime).format()}}</div>
          </template>
        </el-table-column>
        <el-table-column label="ERP编号" width="120">
          <template slot-scope="scope">{{scope.row.erpFormCode === scope.row.formCode ? "" : scope.row.erpFormCode}}</template>
        </el-table-column>
        <el-table-column label="总金额" width="120" align="right">
          <template slot-scope="scope">
            <b class="fc-e">{{$price((scope.row.totalMoney || 0) - (scope.row.discountAmount || 0))}}</b>
          </template>
        </el-table-column>
        <el-table-column label="支付方式" width="100" :formatter="r => {return payTypes[r.payType];}" />
        <el-table-column prop="info" label="备注" min-width="160" show-overflow-tooltip />
        <el-table-column label="付款情况" align="center" width="130">
          <template slot-scope="scope">
            <el-tag :type="payStatus[scope.row.payType][scope.row.payStatus].type" :key="scope.row.id">{{payStatus[scope.row.payType][scope.row.payStatus].label}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="120" align="center">
          <template slot-scope="scope">
            <el-tag :type="status[scope.row.status].type">{{status[scope.row.status].label}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="140px" align="center">
          <div class="row-commands" slot-scope="scope">
            <el-button size="mini" type="text" @click="detail(scope.row)">详细</el-button>
            <template v-if="scope.row.status === 0">
              <el-button size="mini" type="text" @click="edit(scope.row)">修改</el-button>
              <el-button type="text" size="mini" @click="del(scope.row)">删除</el-button>
            </template>
          </div>
        </el-table-column>
      </el-table>

      <el-pagination class="padding-10-0" :total="total" :page-size="size" :current-page="page + 1" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
      <!-- </el-tab-pane>
      </el-tabs>-->
    </div>

    <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="editor.show" :title="editor.title" custom-class="custom-dialog-max-width" @closed="handleEditorClosed">
      <purchase-form ref="purchaseForm" :title.sync="editor.title" @submit="init" @close="editor.show = false" />
    </el-dialog>
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import PurchaseForm from "../../purchase/order/form-view";
import { del as purchaseDel } from "@/api/purchaseOrder";

export default {
  mixins: [initData],
  components: { PurchaseForm },
  props: {
    supplier: String | Number,
  },
  watch: {
    supplier: "toQuery",
  },
  data() {
    return {
      editor: {
        show: false,
        title: "",
      },
      orderType: "purchase",
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      payTypes: ["线上支付", "线下支付"],
      payStatus: [
        [
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "warning",
            label: "部分付款成功",
          },
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "danger",
            label: "付款失败",
          },
          {
            type: "primary",
            label: "付款成功",
          },
        ],
        [
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "warning",
            label: "部分已付款",
          },
          {
            type: "warning",
            label: "部分付款已确认",
          },
          {
            type: "success",
            label: "已付款",
          },
          {
            type: "danger",
            label: "付款失败",
          },
          {
            type: "primary",
            label: "已完成",
          },
        ],
      ],
      status: [
        {
          type: "info",
          label: "编辑中",
        },
        {
          type: "warning",
          label: "已提交",
        },
        {
          type: "success",
          label: "已确认",
        },
        {
          type: "danger",
          label: "已拒绝",
        },
        {
          type: "warning",
          label: "待发货",
        },
        {
          type: "success",
          label: "部分已发货",
        },
        {
          type: "success",
          label: "已发货",
        },
        {
          type: "success",
          label: "部分已收货",
        },
        {
          type: "success",
          label: "已完成",
        },
        {
          type: "info",
          label: "已关闭",
        },
        {
          type: "info",
          label: "已取消",
        },
        {
          type: "info",
          label: "部分已关闭",
        },
      ],
      typeFilters: [
        {
          statusList: [0, 1],
        },
        {
          statusList: [2, 4],
        },
        {
          statusList: [5, 6],
        },
        {
          statusList: [1, 2, 4, 5, 6, 7],
          payStatusList: [0, 2, 4],
        },
      ],
      query: {
        dateRange: null,
        formCode: null,
      },
    };
  },
  methods: {
    beforeInit() {
      switch (this.orderType) {
        case "purchase":
          this.url = "api/purchaseForm";
          let query = JSON.parse(JSON.stringify(this.query));
          if (query.dateRange && query.dateRange.length === 2) {
            query.beginTime = query.dateRange[0];
            query.endTime = query.dateRange[1];
          }
          delete query.dateRange;
          let o = {
            sort: "purchaseTime,desc",
            sellerId: this.supplier,
          };
          if (!isNaN(this.$route.query.t)) {
            Object.assign(o, this.typeFilters[parseInt(this.$route.query.t)]);
          }
          this.params = Object.assign(o, query);
          break;
      }
      return true;
    },
    edit(row) {
      if (this.orderType === "purchase") {
        this.editor.show = true;
        this.$nextTick((_) => {
          this.$refs.purchaseForm && this.$refs.purchaseForm.resetForm(row.id);
        });
      }
    },
    handleEditorClosed() {
      this.$refs.purchaseForm && this.$refs.purchaseForm.clear();
    },
    detail(row) {
      if (this.orderType === "purchase") {
        this.$router.push({
          path: "/mall/order/" + row.id,
        });
      }
    },
    del(row) {
      if (this.orderType === "purchase") {
        this.$confirm(
          `您确定要删除订单编号为【${row.formCode}】的采购订单吗？`,
          "操作确认",
          {
            type: "warning",
          }
        ).then((_) => {
          purchaseDel(row.id).then((res) => {
            this.dleChangePage();
            this.init();
            this.$notify({
              title: "删除采购订单成功",
              type: "success",
              duration: 2500,
            });
          });
        });
      }
    },
    print(id) {},
  },
  activated() {
    if (!window._mall_handleback) {
      this.init();
    }
    window._mall_handleback = false;
  },
};
</script>